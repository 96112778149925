<template>
      <b-modal id="add-avatar-modal" scrollable title="Přidat profilovou fotku">

          <div class="dropzone dropzone-default dz-clickable" @click="$refs.FileInput.click()" v-if="!selectedFile">
            <div class="dropzone-msg dz-message needsclick">
              <h3 class="dropzone-msg-title">{{ $t("users.title.avatar_select") }}</h3>
              <span href="" class="btn btn-primary mt-3">{{ $t("button.select") }}</span>
            </div>
          </div>

          <input ref="FileInput" type="file" style="display: none;" @change="onFileSelect" />
          <VueCropper v-show="selectedFile" ref="cropper" :src="selectedFile" :aspect-ratio="1/1" alt="Source Image"></VueCropper>
          <img :src="image_name" class="profile-img">

          <div class="actions d-flex justify-content-center" v-if="selectedFile">
              <span @click="zoom(0.2)" class="btn btn-xs btn-light-primary btn-hover-primary mr-1"><em class="fa fa-search-plus icon-md"></em></span>
              <span @click="zoom(-0.2)" class="btn btn-xs btn-light-primary btn-hover-primary mr-5"><em class="fa fa-search-minus icon-md"></em></span>

              <span @click="move(-10, 0)" class="btn btn-xs btn-light-primary btn-hover-primary mr-1"><em class="fa fa-caret-left icon-md"></em></span>
              <span @click="move(10, 0)" class="btn btn-xs btn-light-primary btn-hover-primary mr-1"><em class="fa fa-caret-right icon-md"></em></span>
              
              <span @click="move(0, -10)" class="btn btn-xs btn-light-primary btn-hover-primary mr-1"><em class="fa fa-caret-up icon-md"></em></span>
              <span @click="move(0, 10)" class="btn btn-xs btn-light-primary btn-hover-primary mr-5"><em class="fa fa-caret-down icon-md"></em></span>

              <span @click="rotate(90)" class="btn btn-xs btn-light-primary btn-hover-primary mr-1"><em class="fa fa-share icon-md"></em></span>
              <span @click="rotate(-90)" class="btn btn-xs btn-light-primary btn-hover-primary mr-1"><em class="fa fa-reply icon-md"></em></span>
          </div>
          

         <template #modal-footer="{ cancel }">
            <b-button variant="secondary" @click="cancel()" class="mr-3">
              {{ $t("button.close") }}
          </b-button>
          <div v-if="selectedFile">
              <b-button variant="success" @click="saveImage()">
                {{ $t("users.button.avatar_crop") }}
              </b-button>
           </div>
        </template>
      </b-modal>
</template>

<script>
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import ApiService from "@/core/services/api.service";
import { checkErrors } from "@/core/utils/api-errors";
import { UPDATE_CURRENT_DATA } from "@/core/services/store/auth.module";

export default {
  components: { VueCropper },
  props: {
    type: null,
  },
  data() {
    return {
      mime_type: '',
      cropedImage: '',
      autoCrop: false,
      selectedFile: '',
      image: '',
      dialog: false,
      files: '',
    }
  },
  methods: {
    saveImage() {
      this.cropedImage = this.$refs.cropper.getCroppedCanvas().toDataURL()
      this.$refs.cropper.getCroppedCanvas().toBlob((blob) => {
        let formData = new FormData();
        formData.append('avatar', blob)
        if(this.type=="profil"){
          ApiService.setFileHeader();
          ApiService.apiPost("/profil/upload-avatar", formData)
              .then(() => {
                  this.loading = false;
                  this.$parent.$parent.getUser(); 
                  this.$store.dispatch(UPDATE_CURRENT_DATA);
                  this.$bvModal.hide('add-avatar-modal');
              }).catch(error => {
                  checkErrors(error);
          });
        }
        if(this.type=="user"){
          ApiService.setFileHeader();
          ApiService.apiPost("/users/" + this.$route.params.id + "/upload-avatar", formData)
              .then(() => {
                  this.loading = false;
                  this.$parent.$parent.getUser(); 
                  this.$bvModal.hide('add-avatar-modal');
              }).catch(error => {
                  checkErrors(error);
          });
        }
      }, this.mime_type)
    },
    onFileSelect(e) {
      const file = e.target.files[0]
      this.mime_type = file.type
      console.log(this.mime_type)
      if (typeof FileReader === 'function') {
        this.dialog = true
        const reader = new FileReader()
        reader.onload = (event) => {
          this.selectedFile = event.target.result
          this.$refs.cropper.replace(this.selectedFile)
        }
        reader.readAsDataURL(file)
      } else {
        alert('Sorry, FileReader API not supported')
      }
    },
    zoom(percent) {
      this.$refs.cropper.relativeZoom(percent);
    },
    move(offsetX, offsetY) {
      this.$refs.cropper.move(offsetX, offsetY);
    },
    reset() {
      this.$refs.cropper.reset();
    },
    rotate(deg) {
      this.$refs.cropper.rotate(deg);
    },
  },
}
</script>
